import React, { useState } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import FormCheckbox from "../Elements/Form/FormCheckbox"
import Message from "../Elements/Message"
import { healthSurveyValidationSchema } from "./utils/healthSurveyValidationSchema"
import { healthSurveyTicketBody } from "./utils/healthSurveyTicketBody"
import { zendeskApi } from "../../services/zendeskService"

import {
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
  GATSBY_ENV,
} from "gatsby-env-variables"
import { generateRequestTemplate } from "../../services/zendeskService"

const HealthSurvey = ({ pageContext }) => {
  const { module } = pageContext
  const [loading, setLoading] = useState(false)

  let mgcareZendeskConfig = {
    email: GATSBY_ZENDESK_EMAIL,
    apiKey: GATSBY_ZENDESK_API_KEY,
    apiUrl: GATSBY_ZENDESK_SUBDOMAIN,
  }

  const handleSuccessCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleErrorCallback = () => {
    navigate("/404")
    setLoading(false)
  }

  const handleSubmit = async (values, { setFieldError }) => {
    const allValues = Object.values(values);
    const isAtLeastOneChecked = allValues.some(arr => arr.length > 0);

    if (!isAtLeastOneChecked) {
      setFieldError('formError', 'You must select at least one option');
    } else {
      try {
        setLoading(true)
        let zendeskTags = ["adherence_survey", "pulsecare", GATSBY_ENV]
        if (GATSBY_ENV !== "production") zendeskTags = [...zendeskTags, "test"]

        const requestTemplate = generateRequestTemplate({
          subject: "Adherence Survey",
          email: null,
          template: healthSurveyTicketBody,
          templateObjects: values,
          tags: zendeskTags,
        })

        const zendeskResponse = await zendeskApi({ ...mgcareZendeskConfig }).post(
          "/requests.json",
          requestTemplate
        )

        setLoading(false)
        handleSuccessCallback()
      } catch (error) {
        handleErrorCallback()
      }
    }
  }

  return (
    <Layout {...module} pageContext={pageContext}>
      <Container isCentered>
        <Formik
          initialValues={{
            hasDifficultyVisitingDoctor: [],
            hasDiscontinuedIntake: [],
            hasDifficultyReachingMinOrder: [],
            hasDifficultyPaying: [],
            prefersPWDDiscount: [],
            isNotFamiliarWithMechanics: [],
            hasDifficultyOrdering: [],
            prefersOtherChannels: [],
            isNotInMetroManila: [],
            hasMedicineStock: [],
            isNotApplicable: [],
          }}
          validationSchema={healthSurveyValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors }) => (
            <Form>
              <div className="content has-text-left">
                <p>What challenges do you commonly encounter or foresee when it comes to staying on your medication?</p>
                <FormCheckbox
                  name="hasDifficultyVisitingDoctor"
                  options={["You find it difficult to visit your doctor to renew your prescription"]}
                  values={values.hasDifficultyVisitingDoctor}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="hasDiscontinuedIntake"
                  options={["You have discontinued your intake of Sacubitril-Valsartan (Entresto)"]}
                  values={values.hasDiscontinuedIntake}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="hasDifficultyReachingMinOrder"
                  options={["You find it difficult to meet the minimum order of 14+14"]}
                  values={values.hasDifficultyReachingMinOrder}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="hasDifficultyPaying"
                  options={["You find it difficult to pay for your medicines"]}
                  values={values.hasDifficultyPaying}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="prefersPWDDiscount"
                  options={["You prefer to avail of SCPWD discount"]}
                  values={values.prefersPWDDiscount}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="isNotFamiliarWithMechanics"
                  options={["You are not familiar with the program mechanics"]}
                  values={values.isNotFamiliarWithMechanics}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="hasDifficultyOrdering"
                  options={["You find it difficult to order your medicines online"]}
                  values={values.hasDifficultyOrdering}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="prefersOtherChannels"
                  options={["You prefer to buy your medicines from other drugstores or channels"]}
                  values={values.prefersOtherChannels}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="isNotInMetroManila"
                  options={["You live outside Metro Manila"]}
                  values={values.isNotInMetroManila}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="hasMedicineStock"
                  options={["You still have medicines"]}
                  values={values.hasMedicineStock}
                  isRequired={true}
                  errors={errors}
                />
                <FormCheckbox
                  name="isNotApplicable"
                  options={["You feel that the program is not applicable to you"]}
                  values={values.isNotApplicable}
                  isRequired={true}
                  errors={errors}
                />
              </div>
              {errors.formError && (
                <Message color="danger">
                  {errors.formError}
                </Message>
              )}
              <div className="buttons is-centered">
                <button className="button is-primary" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default HealthSurvey
