import axios from "axios"

import {
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
  GATSBY_ZENDESK_BASE_URL,
} from "gatsby-env-variables"

export const zendesk = () => {
  let credentials = {}
  credentials.EMAIL = GATSBY_ZENDESK_EMAIL
  credentials.API_KEY = GATSBY_ZENDESK_API_KEY
  credentials.SUBDOMAIN = GATSBY_ZENDESK_SUBDOMAIN

  let zendeskKey = credentials.EMAIL + "/token:" + credentials.API_KEY
  let encryptedKey = Buffer.from(zendeskKey).toString("base64")

  let api = axios.create({
    baseURL: GATSBY_ZENDESK_BASE_URL,
    headers: {
      Authorization: "Basic " + encryptedKey,
      "Content-Type": "application/json",
      api_url: credentials.SUBDOMAIN,
    },
  })

  return api
}
