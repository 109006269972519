export const healthSurveyTicketBody = (request) => {
  let {
    hasDifficultyVisitingDoctor,
    hasDiscontinuedIntake,
    hasDifficultyReachingMinOrder,
    hasDifficultyPaying,
    prefersPWDDiscount,
    isNotFamiliarWithMechanics,
    hasDifficultyOrdering,
    prefersOtherChannels,
    isNotInMetroManila,
    hasMedicineStock,
    isNotApplicable,
  } = request
  return `Barriers to Adherence Survey

  You find it difficult to visit your doctor to renew your prescription: ${hasDifficultyVisitingDoctor.length === 0 ? "No" : "Yes"}
  You have discontinued your intake of Sacubitril-Valsartan (Entresto): ${hasDiscontinuedIntake.length === 0 ? "No" : "Yes"}
  You find it difficult to meet the minimum order of 14+14: ${hasDifficultyReachingMinOrder.length === 0 ? "No" : "Yes"}
  You find it difficult to pay for your medicines: ${hasDifficultyPaying.length === 0 ? "No" : "Yes"}
  You prefer to avail of SCPWD discount: ${prefersPWDDiscount.length === 0 ? "No" : "Yes"}
  You are not familiar with the program mechanics: ${isNotFamiliarWithMechanics.length === 0 ? "No" : "Yes"}
  You find it difficult to order your medicines online: ${hasDifficultyOrdering.length === 0 ? "No" : "Yes"}
  You prefer to buy your medicines from other drugstores or channels: ${prefersOtherChannels.length === 0 ? "No" : "Yes"}
  You live outside Metro Manila: ${isNotInMetroManila.length === 0 ? "No" : "Yes"}
  You still have medicines: ${hasMedicineStock.length === 0 ? "No" : "Yes"}
  You feel that the program is not applicable to you: ${isNotApplicable.length === 0 ? "No" : "Yes"}
  `
}