import axios from "axios"
import { zendesk } from "./zendesk"

const TEST_ENV = process.env.GATSBY_ENV === "production" ? "" : "[TEST]"

export const zendeskApi = ({
  email,
  apiKey,
  apiUrl,
}) => {
  let zendeskKey = email + "/token:" + apiKey
  let encryptedKey = Buffer.from(zendeskKey).toString("base64")
  let api = axios.create({
    baseURL: apiUrl,
    headers: {
      Authorization: "Basic " + encryptedKey,
      "Content-Type": "application/json",
    },
  })
  return api
}

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ""
  sliceSize = sliceSize || 512
  let byteCharacters = atob(b64Data)
  let byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  let blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const generateUploadFiles = (uploadedFiles) =>
  uploadedFiles.map((response) => {
    return response.data.upload.token
  })

const checkFileSize = (fileArray) => {
  const MAX_SIZE = 10485760
  for (let i = 0; i < fileArray.length; i++) {
    if (fileArray[i].path.size >= MAX_SIZE) {
      let errorMessage = {
        code: 403,
        message: "File is too big to attach in zendesk.",
      }
      throw errorMessage
    }
  }
}

export const sendToZendesk = async (request, fileArray, module) => {
  checkFileSize(fileArray)
  const uploadedFiles = await zendeskUploadFiles(fileArray)
  const generatedFileToken = await generateUploadFiles(uploadedFiles)
  const comment = { ...request.comment, uploads: generatedFileToken }
  const requestTemplate = { request: { ...request, comment } }
  return await zendesk({ module }).post("/requests", requestTemplate)
}
export const zendeskUploadFiles = (fileArray, callback, zendeskConfig) =>
  axios.all(
    fileArray.map((file) => {
      const b64Data = file.path.split(",")[1]
      const contentType = file.path.split(",")[0].match(/:(.*?);/)[1]
      return zendeskApi({
        ...zendeskConfig,
      }).post(
        `/uploads.json?filename=${file.filename}`,
        b64toBlob(b64Data, contentType),
        { headers: { "Content-Type": "application/binary" } }
      )
    })
  )

// export const zendeskUploadFilesPOP = (fileArray) =>
//   axios.all(
//     fileArray.map(async (file) => {
//       const b64Data = file.path.split(",")[1]
//       const contentType = file.path.split(",")[0].match(/:(.*?);/)[1]
//       const fileResponse = await zendeskApi({ ...zendeskConfig, }).post(
//         `/uploads.json?filename=${file.filename}`,
//         b64toBlob(b64Data, contentType),
//         { headers: { "Content-Type": "application/binary" } }
//       )
//       return fileResponse
//     })
//   )

export const generateRequestTemplate = ({
  subject,
  email,
  template,
  templateObjects,
  tags,
  uploadTokens,
}) => {
  const requestTemplate = {
    request: {
      type: "request",
      subject: `${TEST_ENV} ${subject} ${email ? email : ""}`,
      comment: {
        body: template(templateObjects),
      },
      tags,
    }
  };

  // Conditionally add requester if email is present
  if (email) {
    requestTemplate.request.requester = { name: email, email };
  }

  if (uploadTokens) {
    requestTemplate.request.comment.uploads = uploadTokens.map(token => (token));
  }

  return requestTemplate;
}


// {
//   "data": {
//       "upload": {
//           "token": "nuG4xUUljslmvtsqPN2IrSC2U",
//           "expires_at": "2023-09-25T03:17:30Z",
//           "attachments": [
//               {
//                   "url": "https://mgjnj.zendesk.com/api/v2/attachments/23247826648857.json",
//                   "id": 23247826648857,
//                   "file_name": "pop_20230922_111729",
//                   "content_url": "https://mgjnj.zendesk.com/attachments/token/bERZrJQwpTwj4vFymKmENxdsO/?name=pop_20230922_111729",
//                   "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/bERZrJQwpTwj4vFymKmENxdsO/?name=pop_20230922_111729",
//                   "content_type": "image/jpeg",
//                   "size": 10108,
//                   "width": 259,
//                   "height": 194,
//                   "inline": false,
//                   "deleted": false,
//                   "malware_access_override": false,
//                   "malware_scan_result": "not_scanned",
//                   "thumbnails": [
//                       {
//                           "url": "https://mgjnj.zendesk.com/api/v2/attachments/23247818213145.json",
//                           "id": 23247818213145,
//                           "file_name": "pop_20230922_111729_thumb",
//                           "content_url": "https://mgjnj.zendesk.com/attachments/token/UGF34cNoPs9gpPikZyFQ77Fuo/?name=pop_20230922_111729_thumb",
//                           "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/UGF34cNoPs9gpPikZyFQ77Fuo/?name=pop_20230922_111729_thumb",
//                           "content_type": "image/jpeg",
//                           "size": 1572,
//                           "width": 80,
//                           "height": 60,
//                           "inline": false,
//                           "deleted": false,
//                           "malware_access_override": false,
//                           "malware_scan_result": "not_scanned"
//                       }
//                   ]
//               }
//           ],
//           "attachment": {
//               "url": "https://mgjnj.zendesk.com/api/v2/attachments/23247826648857.json",
//               "id": 23247826648857,
//               "file_name": "pop_20230922_111729",
//               "content_url": "https://mgjnj.zendesk.com/attachments/token/bERZrJQwpTwj4vFymKmENxdsO/?name=pop_20230922_111729",
//               "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/bERZrJQwpTwj4vFymKmENxdsO/?name=pop_20230922_111729",
//               "content_type": "image/jpeg",
//               "size": 10108,
//               "width": 259,
//               "height": 194,
//               "inline": false,
//               "deleted": false,
//               "malware_access_override": false,
//               "malware_scan_result": "not_scanned",
//               "thumbnails": [
//                   {
//                       "url": "https://mgjnj.zendesk.com/api/v2/attachments/23247818213145.json",
//                       "id": 23247818213145,
//                       "file_name": "pop_20230922_111729_thumb",
//                       "content_url": "https://mgjnj.zendesk.com/attachments/token/UGF34cNoPs9gpPikZyFQ77Fuo/?name=pop_20230922_111729_thumb",
//                       "mapped_content_url": "https://mgjnj.zendesk.com/attachments/token/UGF34cNoPs9gpPikZyFQ77Fuo/?name=pop_20230922_111729_thumb",
//                       "content_type": "image/jpeg",
//                       "size": 1572,
//                       "width": 80,
//                       "height": 60,
//                       "inline": false,
//                       "deleted": false,
//                       "malware_access_override": false,
//                       "malware_scan_result": "not_scanned"
//                   }
//               ]
//           }
//       }
//   },
//   "status": 201,
//   "statusText": "",
//   "headers": {
//       "cache-control": "max-age=0, private, must-revalidate",
//       "content-length": "2273",
//       "content-type": "application/json; charset=utf-8"
//   },
//   "config": {
//       "url": "/uploads.json?filename=pop_20230922_111729",
//       "method": "post",
//       "data": {},
//       "headers": {
//           "Accept": "application/json, text/plain, */*",
//           "Content-Type": "application/binary",
//           "Authorization": "Basic am5qLWxlYWRAbWVkZ3JvY2VyLmNvbS90b2tlbjoxVlQ4ZHBsNTZrdnpVSXk2c1hRcGJqc1dDR0llWnNVc1ZiZkp1TWNE"
//       },
//       "baseURL": "https://mgjnj.zendesk.com/api/v2",
//       "transformRequest": [
//           null
//       ],
//       "transformResponse": [
//           null
//       ],
//       "timeout": 0,
//       "xsrfCookieName": "XSRF-TOKEN",
//       "xsrfHeaderName": "X-XSRF-TOKEN",
//       "maxContentLength": -1
//   },
//   "request": {}
// }

// {
//   "data": "<!DOCTYPE html><html class=\"has-navbar-fixed-top\"><head><meta charSet=\"utf-8\"/><meta http-equiv=\"x-ua-compatible\" content=\"ie=edge\"/><meta name=\"viewport\" content=\"width=device-width, initial-scale=1, shrink-to-fit=no\"/><meta name=\"note\" content=\"environment=development\"/><link rel=\"sitemap\" type=\"application/xml\" href=\"/sitemap/sitemap-index.xml\"/><title data-react-helmet=\"true\"></title><link rel=\"icon\" href=\"/icons/icon-48x48.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"manifest\" href=\"/manifest.webmanifest\"/><meta name=\"theme-color\" content=\"#0d6d6e\"/><link rel=\"apple-touch-icon\" sizes=\"48x48\" href=\"/icons/icon-48x48.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"apple-touch-icon\" sizes=\"72x72\" href=\"/icons/icon-72x72.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"apple-touch-icon\" sizes=\"96x96\" href=\"/icons/icon-96x96.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"apple-touch-icon\" sizes=\"144x144\" href=\"/icons/icon-144x144.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"apple-touch-icon\" sizes=\"192x192\" href=\"/icons/icon-192x192.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"apple-touch-icon\" sizes=\"256x256\" href=\"/icons/icon-256x256.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"apple-touch-icon\" sizes=\"384x384\" href=\"/icons/icon-384x384.png?v=81341621f06e4c5345064de1ae911f03\"/><link rel=\"apple-touch-icon\" sizes=\"512x512\" href=\"/icons/icon-512x512.png?v=81341621f06e4c5345064de1ae911f03\"/><script src=\"/socket.io/socket.io.js\"></script></head><body><div id=\"___gatsby\"></div><script src=\"/polyfill.js\" nomodule=\"\"></script><script src=\"/commons.js\"></script></body></html>",
//   "status": 200,
//   "statusText": "OK",
//   "headers": {
//       "accept-ranges": "bytes",
//       "access-control-allow-origin": "*",
//       "cache-control": "public, max-age=0",
//       "connection": "close",
//       "content-length": "1635",
//       "content-type": "text/html; charset=UTF-8",
//       "date": "Fri, 22 Sep 2023 03:20:40 GMT",
//       "etag": "W/\"663-18abae5cf65\"",
//       "last-modified": "Fri, 22 Sep 2023 03:18:57 GMT",
//       "x-powered-by": "Express"
//   },
//   "config": {
//       "url": "/uploads.json?filename=pop_20230922_112040",
//       "method": "post",
//       "data": {},
//       "headers": {
//           "Accept": "application/json, text/plain, */*",
//           "Content-Type": "application/binary",
//           "Authorization": "Basic dW5kZWZpbmVkL3Rva2VuOnVuZGVmaW5lZA=="
//       },
//       "transformRequest": [
//           null
//       ],
//       "transformResponse": [
//           null
//       ],
//       "timeout": 0,
//       "xsrfCookieName": "XSRF-TOKEN",
//       "xsrfHeaderName": "X-XSRF-TOKEN",
//       "maxContentLength": -1,
//       "maxBodyLength": -1
//   },
//   "request": {}
// }