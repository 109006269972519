import * as Yup from "yup"

export const healthSurveyValidationSchema = Yup.object().shape({
  hasDifficultyVisitingDoctor: Yup.array(),
  hasDiscontinuedIntake: Yup.array(),
  hasDifficultyReachingMinOrder: Yup.array(),
  hasDifficultyPaying: Yup.array(),
  prefersPWDDiscount: Yup.array(),
  isNotFamiliarWithMechanics: Yup.array(),
  hasDifficultyOrdering: Yup.array(),
  prefersOtherChannels: Yup.array(),
  isNotInMetroManila: Yup.array(),
  hasMedicineStock: Yup.array(),
  isNotApplicable: Yup.array(),
}).test('atLeastOne', 'You must select at least one option', value => {
  const allValues = Object.values(value);
  return allValues.some(arr => arr.length > 0);
});